import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChipsFilterService } from '../chips-filter.service';
import { combineLatest, Observable } from 'rxjs';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { MatInput } from '@angular/material/input';
import { map, shareReplay } from 'rxjs/operators';
import { mapNotEmpty, negateBool } from '@tremaze/shared/util/rxjs';
import { ChipsFilterButtonFilterDirective } from '../chips-filter-button-filter.directive';

@Component({
  selector: 'tremaze-chips-filter-dialog',
  templateUrl: './chips-filter-dialog.component.html',
  styleUrls: ['./chips-filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsFilterDialogComponent<T extends { id?: string }> {
  readonly displayWith = this._data.displayWith;
  readonly items$: Observable<T[]> = combineLatest([
    this._service.missingItems$,
    this._service.items$,
  ]).pipe(
    map((r) => [...r[0], ...r[1]]),
    shareReplay(1)
  );
  readonly filterCount$ = this._service.filterCount$;
  readonly hiddenElementsCount$ = this._service.hiddenElementsCount$;
  readonly visibleElementsCount$ = this.items$.pipe(
    map((items) => items.length)
  );
  readonly totalElementsCount$: Observable<number> =
    this._service.totalElements$;
  readonly disableResetFilterButton$ = this.filterCount$.pipe(
    map((count) => count === 0)
  );
  readonly showLoader$ = this._service.isLoading$;
  readonly textFilterEmpty$ = this._service.textFilter$.pipe(
    mapNotEmpty(),
    negateBool()
  );

  readonly showLoadMoreButton$ = combineLatest([
    this._service.totalElements$,
    this.visibleElementsCount$,
  ]).pipe(
    map(([totalElements, visibleElements]) => visibleElements < totalElements)
  );

  @ViewChild('filter', { read: MatInput }) filter!: MatInput;

  get projectedFilters(): QueryList<ChipsFilterButtonFilterDirective> {
    return this._data.projectedFilters;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      service: ChipsFilterService<T>;
      displayWith: (item: T) => string;
      label: string;
      getAvatar?: (item: T) => FileStorage | undefined;
      getInitials?: (item: T) => string | undefined;
      projectedFilters: QueryList<ChipsFilterButtonFilterDirective>;
    }
  ) {}

  get _showAvatar(): boolean {
    return !!this._data.getAvatar || !!this._data.getInitials;
  }

  get _service() {
    return this._data.service;
  }

  get label(): string {
    return this._data.label;
  }

  onClickResetFilterButton(): void {
    this._service.resetFilter();
  }

  onClickResetTextFilterButton(): void {
    this.filter.value = null;
    this._service.setFilterValue();
  }

  getAvatar(item: T): FileStorage | undefined {
    return this._data.getAvatar?.(item);
  }

  getInitials(item: T): string {
    return this._data.getInitials?.(item) ?? '??';
  }

  onChangeFilterText(event: Event): void {
    this._service.setFilterValue((event.target as HTMLInputElement).value);
  }

  isItemSelected$(item: T): Observable<boolean> {
    return this._service.isItemSelected$(item);
  }

  toggleSelection(item: T): void {
    this._service.toggleSelection(item);
  }

  onClickedLoadMoreButton(): void {
    this._service.loadNextPage();
  }
}
