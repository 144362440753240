import { Environment } from './type';

export const environment: Environment = {
  production: true,
  clientId: 'tagea-cc',
  oidcConfigURL:
    'https://keycloak.tremaze.de/auth/realms/tremaze-prod/.well-known/openid-configuration',
  basePath: 'https://api.tagea.app/',
  enableServiceWorker: true,
  lightThemeLogoPath: null,
  darkThemeLogoPath: null,
  iconPath: null,
  productName: null,
  disableFreshChat: false,
  stage: 'PROD',
  enableVitaAdvisorFor: '265bd4cb-eeac-4703-8c14-1ebf64b1c032',
};
