import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { distinctUntilChanged, Observable, skip, Subject, tap } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ChipsFilterDialogComponent } from '../chips-filter-dialog/chips-filter-dialog.component';
import { ChipsFilterService } from '../chips-filter.service';
import { Pagination } from '@tremaze/shared/models';
import { FileStorage } from '@tremaze/shared/feature/file-storage/types';
import { ControlValueAccessor } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { ChipsFilterButtonFilterDirective } from '../chips-filter-button-filter.directive';

@Component({
  selector: 'tremaze-chips-filter-button',
  templateUrl: './chips-filter-button.component.html',
  styleUrls: ['./chips-filter-button.component.scss'],
  providers: [
    {
      provide: ChipsFilterService,
      useFactory: (
        parentInjector: Injector,
        service: ChipsFilterService<never>
      ) => {
        if (!service) {
          return new ChipsFilterService();
        }
        return service;
      },
      deps: [Injector, [new Optional(), new SkipSelf(), ChipsFilterService]],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsFilterButtonComponent<T extends { id?: string }>
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Output() readonly filterChange = new EventEmitter<T[]>();
  @Input() label = 'Filter';
  @Input() iconName?: string;

  private _skipNextChangeOutput?: boolean;

  @Input() set initialValues(value: T[] | undefined) {
    if (value?.length) {
      if (this._skipNextChangeOutput === undefined) {
        this._skipNextChangeOutput = true;
      }
      this._service.setValue(value);
    } else {
      if (this._skipNextChangeOutput === undefined) {
        this._skipNextChangeOutput = false;
      }
    }
  }

  get loadItems(): (
    page: number,
    pageSize: number,
    filterValue?: string
  ) => Observable<Pagination<T>> {
    return this._loadItems;
  }

  @Input()
  set loadItems(
    value: (
      page: number,
      pageSize: number,
      filterValue?: string
    ) => Observable<Pagination<T>>
  ) {
    this._loadItems = value;
    this._service.init(value);
  }

  private _loadItems!: (
    page: number,
    pageSize: number,
    filterValue?: string
  ) => Observable<Pagination<T>>;
  @Input() getAvatar?: (item: T) => FileStorage | undefined;
  readonly filterCount$: Observable<number> = this._service.filterCount$;
  readonly hasSelectedItems$: Observable<boolean> = this.filterCount$.pipe(
    map((v) => v > 0)
  );
  @ViewChild(MatButton, { static: true })
  private readonly _buttonComponent!: MatButton;
  private _destroyed$ = new Subject();
  @Input() getInitials?: (item: T) => string | undefined;

  @Input() keepServiceAfterDestruction = false;

  @ContentChildren(ChipsFilterButtonFilterDirective)
  filters?: QueryList<ChipsFilterButtonFilterDirective>;

  constructor(
    private readonly _service: ChipsFilterService<T>,
    private dialog: MatDialog
  ) {
    this._service.selectedItems$
      .pipe(
        distinctUntilChanged(
          (a, b) =>
            a.length === b.length &&
            a.every((v, i) => v.id === b[i].id) &&
            b.every((v, i) => v.id === a[i].id)
        ),
        skip(1),
        tap((v) => {
          if (!this._skipNextChangeOutput) {
            this.filterChange.emit(v);
            this.onChange(v);
          } else {
            this._skipNextChangeOutput = false;
          }
        }),
        takeUntil(this._destroyed$)
      )
      .subscribe();
  }

  @Input() displayWith: (item: T) => string = (item) => item.toString();

  ngOnInit() {
    if (this.initialValues && this.initialValues.length > 0)
      this._service.setValue(this.initialValues);
  }

  ngOnDestroy() {
    this._destroyed$.next(null);
    this._destroyed$.complete();
    if (!this.keepServiceAfterDestruction) {
      this._service.destroy();
    }
  }

  reload() {
    this._service.reload();
  }

  onClickButton() {
    this._showDialog();
  }

  resetFilter() {
    this._service.resetFilter();
  }

  onChange = (_: any) => null;

  onTouched = (_: any) => null;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._buttonComponent.disabled = isDisabled;
  }

  writeValue(obj: T[] | null): void {
    this._service.setValue(obj ?? []);
  }

  private _showDialog(): void {
    this.dialog
      .open(ChipsFilterDialogComponent, {
        data: {
          service: this._service,
          displayWith: this.displayWith,
          label: this.label,
          getAvatar: this.getAvatar,
          getInitials: this.getInitials,
          projectedFilters: this.filters,
        },
        panelClass: 'relative-dialog',
        autoFocus: 'dialog',
      })
      .afterClosed()
      .subscribe((_) => this._service.setFilterValue());
  }
}
